type EnumValues<T> = T[keyof T];

export const PAGE_ENVIRONMENT = {
  STAGING: 'staging',
  PRODUCTION: 'live',
} as const;

export const FORMULAR_EVENTS = {
  ERROR: 'formular_error',
  STEP: 'formular_step',
  SUBMIT: 'formular_submit',
} as const;

type FormularEvents = EnumValues<typeof FORMULAR_EVENTS>;

export const FORMULAR_STEP = {
  CALL: 'aufruf',
  CONVERSION: 'conversion',
} as const;

type FormularStep = EnumValues<typeof FORMULAR_STEP>;

export const FORMULAR_TYPE = {
  CONTACT: 'kontakt',
  COMPETITION: 'gewinnspiel',
} as const;

type FormularType = EnumValues<typeof FORMULAR_TYPE>;

export type DataLayerPushProps = {
  pageName: string;
  pageParams: string;
}

export type FormularTrackingProps = {
  formularName: string;
  formularStep: FormularStep | string;
  formularType: FormularType | string;
  formularStepIndex?: number;
  formularErrors?: {
    [key: string]: string;
  };
  event: FormularEvents;
}

export type AdobeAnalyticsCtaAndButtonTrackingProps = {
  trackingEvent: 'cta_clicked' | 'social_signal_clicked' | 'download_clicked';
  pageSlug: string;
  linkText: string;
  linkUrl: string;
  linkDomain: string;
  linkCssClasses: string;
  event?: 'tmsLinkClick';
  depthOfElement?: number;
}

export type DatepickerBookDateError = {
  title: string,
  name: string,
  firstname: string,
  mail: string,
  date: string,
  bundesland: string,
};

export type ContactFormError = {
  name: string,
  firstname: string,
  email: string,
  text: string,
  phone: string,
  anrede: string,
}

export type AdobeTrackingButtonProps = {
  needsValidation?: boolean,
  isDownload?: boolean,
  isSocialMediaButton?: boolean,
  text: string,
  refLink?: string,
  depthOfElement?: number,
}

export type AdobeDynamicInitPageTrackingProps = {
  pageName: string,
  pageParams: string,
}
