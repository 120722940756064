import {
  AdobeAnalyticsCtaAndButtonTrackingProps,
  DataLayerPushProps,
  FORMULAR_EVENTS,
  FormularTrackingProps, PAGE_ENVIRONMENT,
} from './types';

function initDataLayer() {
  return window?.dataLayer || [];
}

export default function dataLayerHandler({
  pageName, pageParams,
}: DataLayerPushProps) {
  const pageEnvironment = process.env.NODE_ENV === 'development' ? PAGE_ENVIRONMENT.STAGING : PAGE_ENVIRONMENT.PRODUCTION;
  if (!window.dataLayer) window.dataLayer = initDataLayer();
  window.dataLayer.push({
    pageEnvironment,
    pageName,
    pageParams,
    event: 'cms.init',
  });
}

export function formularTracking({
  formularName, formularStep, formularType, formularErrors, event, formularStepIndex,
}: FormularTrackingProps) {
  if (!window.dataLayer) window.dataLayer = initDataLayer();

  const dataLayerObject = {
    'ga_four.formular_name': formularName,
    'ga_four.formular_step': event === FORMULAR_EVENTS.SUBMIT ? 'conversion' : formularStep,
    'ga_four.formular_step_index': formularStepIndex,
    'ga_four.formular_typ': formularType,
    event,
  };

  if (formularErrors) {
    // @ts-ignore
    dataLayerObject['ga_four.formular_fehler'] = Object.values(formularErrors).filter((errorValue) => errorValue !== '').join(', ');
  }

  window.dataLayer.push(dataLayerObject);
}

export function adobeAnalyticsCtaAndButtonTracking({
  trackingEvent, pageSlug, linkText, linkUrl, linkDomain, linkCssClasses, event,
  depthOfElement,
}: AdobeAnalyticsCtaAndButtonTrackingProps) {
  if (!window.dataLayer) window.dataLayer = initDataLayer();
  const dataLayerObject = {
    'event_tracking.event': trackingEvent,
    'event_tracking.page_location': pageSlug,
    'event_tracking.link_text': linkText,
    'event_tracking.link_url': linkUrl,
    'event_tracking.link_domain': linkDomain,
    'event_tracking.link_classes': linkCssClasses,
  };

  if (event) {
    // @ts-ignore
    dataLayerObject.event = event;
  }
  if (event) {
    // @ts-ignore
    dataLayerObject['event_tracking.depth_of_element'] = depthOfElement;
  }
  window.dataLayer.push(dataLayerObject);
}

export const setAdobeAnalyticsScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/plain';
  script.setAttribute('data-usercentrics', 'Adobe Tag Manager');
  script.src = process.env.REACT_APP_ADOBE_ANALYTICS_TAG_MANAGER_SCRIPT || '';
  document.head.appendChild(script);
};
