import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LinkContainer, LinkHref, LinkHrefTermin } from '../style';
import { handleAPICallAndRedirect } from '../../../../partials/button/utils';
import { adobeAnalyticsCtaAndButtonTracking } from '../../../../utils/adobeAnalytics/trackingFunctions';
import { ctaAndButtonTracking } from '../../../../utils/adobeAnalytics/dataLayerContent';

interface PageProps {
    data: {
      pageSlug?: string,
      name: string
    }
}

function TopMenuLink({
  data,
}: PageProps) {
  const { name, pageSlug } = data;
  const { pathname } = window.location;
  const handleButtonTracking = async (event: React.MouseEvent) => {
    event.preventDefault();
    await handleAPICallAndRedirect({
      href: pageSlug || '', isDownload: false, pathname, buttonIdentifier: 'TerminNavbar', isNewTab: false,
    });
    adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
      text: 'TerminNavbar',
      refLink: pageSlug || '',
    }));
  };
  if (name === 'Terminbuchung') {
    return (
      <LinkHrefTermin
        key={name || ''}
        href={pageSlug || ''}
        onClick={(event) => handleButtonTracking(event)}
      >
        {name}
      </LinkHrefTermin>
    );
  }
  return (
    <LinkContainer>
      <ArrowForwardIcon fontSize="small" aria-hidden="true" data-nosnippet />
      <LinkHref
        key={name || ''}
        href={pageSlug || ''}
      >
        {name || ''}
      </LinkHref>
    </LinkContainer>
  );
}
export default TopMenuLink;
