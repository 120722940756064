import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import Header from './webiny/components/navigation';
import FooterComponent from './components/footer';
import { GET_NAVIGATION } from './webiny/components/navigation/api/query';
import { GET_SOCIAL_MEDIA } from './webiny/components/navigation/apiSocialMedia/query';
import { socialMediaVar } from './webiny/components/navigation/apiSocialMedia/queryTemplate/SocialMedia';
import { navbarVar } from './webiny/components/navigation/api/model/Navigation';
import SEO from './webiny/components/SEO';
import './App.css';
import { STATICPAGES } from './constants';

import SkeletonLoader, { NavBarSkeleton } from './components/skeletonContentLoader';
import Page from './webiny/Page';
import StickyComponent from './components/stickyComponent';
import useWindowWidthCheck from './hooks/useWindowWidthCheck';
import { setAdobeAnalyticsScript } from './utils/adobeAnalytics/trackingFunctions';

const AdessoCheckPage = lazy(() => import('./pages/AdessoKlickstrecke'));
const AdessoCheckSuccessPage = lazy(() => import('./pages/AdessoKlickstrecke/components/checkSuccessPage'));
const SeoOld = lazy(() => import('./components/SEOold'));
const ContactPage = lazy(() => import('./pages/contactPage/kontakt'));
const AppointmentPage = lazy(() => import('./pages/contactPage/termin'));
const CompetitionSuccessPage = lazy(() => import('./pages/competitionSuccessPage'));
const EyeCatcher = lazy(() => import('./components/eyeCatcher'));
const NotFoundPage = lazy(() => import('./pages/fehlerseite'));
const FinanzenGehaltszettelPage = lazy(() => import('./pages/finanzen/finanzenGehaltszettelPage'));
const MarketeerPage = lazy(() => import('./pages/marketeer'));
const NewsletterPopUp = lazy(() => import('./components/newsletterPopup'));
const DateiName = lazy(() => import('./pages/dateiname'));
const Locator = lazy(() => import('./components/locator/locator'));
const NewsletterPage = lazy(() => import('./pages/newsletter'));
const NewsletterExpiredPage = lazy(() => import('./pages/newsletter/newsletterExpired'));
const Gewinnspiel = lazy(() => import('./pages/Gewinnspiel'));
const PodcastsStartPage = lazy(() => import('./pages/podcasts/podcastsStartPage'));

function Placeholder() {
  return <SkeletonLoader />;
}

/* handling of static pages that are supposed to remain static  */
function getStaticPage(staticPageName: string) {
  if (staticPageName === STATICPAGES.TERMINBUCHUNG) {
    return (
      <>
        <SeoOld
          title="Buche direkt einen Online-Termin mit unseren Expert:innen"
          pathSlug="/terminbuchung"
          keywords={['Terminbuchung', 'Mein Lehramt']}
          metaDescription="Wir beantworten dir gerne alle Fragen rund um deine persönliche Absicherung in Studium, Referendariat und als Lehrkraft - buch dir direkt einen Termin."
        />
        <AppointmentPage />
        <NewsletterPopUp />
      </>
    );
  }
  return <NotFoundPage />;
}

function App() {
  useEffect(() => {
    const getCounter = localStorage.getItem('showNewsletterCounter');
    const getShowNewsletter = localStorage.getItem('showNewsletterPopup');

    if (window.location.pathname === '/index.html') {
      window.location.pathname = '/';
    }

    setTimeout(() => {
      if (!getShowNewsletter) {
        localStorage.setItem('showNewsletterPopup', '0');
      }
      if (!getCounter && (document.referrer).includes('mein-lehramt')) {
        localStorage.setItem('showNewsletterCounter', '1');
      }
      if ((document.referrer).includes('mein-lehramt') && getCounter && getCounter !== '0') {
        const counterInt = parseInt(getCounter, 10);
        localStorage.setItem('showNewsletterCounter', (counterInt + 1).toString());
        if (getCounter === '2') {
          localStorage.setItem('showNewsletterPopup', '1');
        } else {
          localStorage.setItem('showNewsletterPopup', '0');
        }
      }
    }, 300);

    setAdobeAnalyticsScript();
  }, []);

  useQuery(GET_NAVIGATION);
  const { isLoading: isLoadingNavbar, data: navbarData } = useReactiveVar(navbarVar);

  useQuery(GET_SOCIAL_MEDIA);
  const { data: socialMediaData, isLoading: isLoadingSocialMedia } = useReactiveVar(socialMediaVar);
  useEffect(() => {
    const getCounter = localStorage.getItem('showNewsletterCounter');
    const getShowNewsletter = localStorage.getItem('showNewsletterPopup');
    if (!localStorage.getItem('showEyecatcher')) {
      localStorage.setItem('showEyecatcher', '0');
    }
    if (!getShowNewsletter) {
      localStorage.setItem('showNewsletterPopup', '0');
    }
    if (!getCounter && (document.referrer).includes('mein-lehramt.de')) {
      localStorage.setItem('showNewsletterCounter', '1');
    }
    if ((document.referrer).includes('mein-lehramt.de') && getCounter && getCounter !== '0') {
      const counterInt = parseInt(getCounter, 10);
      localStorage.setItem('showNewsletterCounter', (counterInt + 1).toString());
      if (getCounter === '2') {
        localStorage.setItem('showNewsletterPopup', '1');
      } else {
        localStorage.setItem('showNewsletterPopup', '0');
      }
    }
  }, []);
  const [showInfo, setShowInfo] = useState(!!(localStorage.getItem('showEyecatcher') === '1' && localStorage.getItem('showEyecatcherScroll') === '1'));
  const isDesktopView = useWindowWidthCheck(768);

  return (
    <Suspense fallback={(
      <div>
        {isLoadingNavbar ? <NavBarSkeleton /> : <Header navbarData={navbarData[0]} isLoadingNavbar={isLoadingNavbar} socialMediaData={socialMediaData[0]} isLoadingSocialMedia={isLoadingSocialMedia} /> }
        <div className="App">
          <Placeholder />
        </div>
      </div>
    )}
    >
      <Router>
        {isLoadingNavbar ? <NavBarSkeleton /> : <Header navbarData={navbarData[0]} isLoadingNavbar={isLoadingNavbar} socialMediaData={socialMediaData[0]} isLoadingSocialMedia={isLoadingSocialMedia} /> }
        <div className="App">
          <Routes>
            {Object.values(STATICPAGES).map((staticPageSlug) => (
              <Route
                key={staticPageSlug}
                path={staticPageSlug}
                element={getStaticPage(staticPageSlug)}
              />
            ))}
            {/* DELETE THE FOLLOWING STATIC PAGES WHEN THEIR CONTENT IS IN WEBINY:  */}
            <Route
              path="/ahrefs-site-verification_ba8bca77d195e5518efb3e5b71f9a6ea1524fc34c036487c2d5663795f389abd"
              element={(
                <>
                  <SeoOld
                    title="Dateiname"
                    pathSlug="/ahrefs-site-verification_ba8bca77d195e5518efb3e5b71f9a6ea1524fc34c036487c2d5663795f389abd"
                    keywords={['Mein Lehramt']}
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <DateiName />
                </>
                )}
            />
            <Route
              path="/marketeer"
              element={(
                <>
                  <SeoOld
                    title="Werde Marketeer bei MeinLehramt"
                    pathSlug="/marketeer"
                    keywords={['Marketeer', 'Mein Lehramt']}
                    metaDescription="Auf der Jobsuche? Wenn du für dein Studium brennst und auf Social Media zu Hause bist, werde jetzt Marketeer im MeinLehramt-Team."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <MarketeerPage />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                </>
                )}
            />
            <Route
              path="/check"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="MeinLehramt – Tipps und Infos für (angehende) Lehrer:innen"
                    pathSlug="/check"
                    keywords={['Mein Lehramt', 'Check']}
                    metaDescription="Mein Lehramt unterstützt Lehramtsstudierende, Referendar:innen und (angehende) Lehrer:innen beim Einstieg ins Berufsleben und darüber hinaus."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <AdessoCheckPage />
                  {' '}

                </>
)}
            />

            <Route
              path="/check/success"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="MeinLehramt – Tipps und Infos für (angehende) Lehrer:innen"
                    pathSlug="/check/success"
                    keywords={['Mein Lehramt', 'Check']}
                    metaDescription="Mein Lehramt unterstützt Lehramtsstudierende, Referendar:innen und (angehende) Lehrer:innen beim Einstieg ins Berufsleben und darüber hinaus."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <AdessoCheckSuccessPage />
                </>
                )}
            />

            <Route
              path="/gewinnspiel"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Findest du alle MeinLehramt-Ostereier?"
                    pathSlug="/gewinnspiel"
                    keywords={['Gewinnspiel', 'Mein Lehramt']}
                    metaDescription="Finde alle versteckten MeinLehramt-Gegenstände und gewinne einen frostigen Preis."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <Gewinnspiel />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                  {' '}

                </>
                )}
            />

            <Route
              path="/kontakt"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Schreibe uns eine Nachricht"
                    pathSlug="/kontakt"
                    keywords={['Kontakt', 'Mein Lehramt']}
                    metaDescription="Dein direkter Draht zu uns - per Formular, E-Mail oder Telefon. Wir freuen uns auf deine Nachricht."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <ContactPage />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                  {' '}

                </>
                )}
            />

            <Route
              path="/teilnahme"
              element={(
                <>
                  {' '}
                  <SeoOld title="Teilnahme" pathSlug="/teilnahme" keywords={['Teilnahme', 'Mein Lehramt']} />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <CompetitionSuccessPage />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                  {' '}

                </>
                )}
            />

            <Route
              path="/finanzen/gehaltszettel"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Referendariat Lehramt – Gehaltsabrechnung an Beispiel erklärt"
                    pathSlug="/finanzen/gehaltszettel"
                    keywords={['Finanzen', 'Gehaltszettel', 'Mein Lehramt']}
                    metaDescription="Verstehst du deinen Gehaltszettel? Brutto, Netto, Steuerabzüge und Lohnsteuerklasse – wir erklären dir, was die Inhalte deiner Besoldungsmitteilung bedeuten."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <FinanzenGehaltszettelPage />
                  <NewsletterPopUp />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                </>
                )}
            />

            <Route
              path="/newsletter/expired"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Newsletter MeinLehramt - Bestätigungslink abgelaufen"
                    pathSlug="/newsletter/expired"
                    keywords={['Newsletter', 'Mein Lehramt']}
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <NewsletterExpiredPage />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                  {' '}

                </>
                )}
            />

            <Route
              path="/newsletter"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Newsletter MeinLehramt - verpasse nie wieder neue Artikel"
                    pathSlug="/newsletter"
                    keywords={['Newsletter', 'Mein Lehramt']}
                    metaDescription="Erhalte 1x monatlich exklusive Inhalte, Infos zu spannenden Events und attraktiven Gewinnspielen."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <NewsletterPage />
                  <EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} />
                  {' '}

                </>
                )}
            />

            <Route
              path="/podcasts"
              element={(
                <>
                  {' '}
                  <SeoOld
                    title="Spannende Podcasts zu Studium, Ref und darüber hinaus"
                    pathSlug="/podcasts"
                    keywords={['Mein Lehramt', 'Podcast']}
                    metaDescription="Unsere Podcast-Reihe mit spannenden Interviewpartnern und Gästen, die dich mit ihren Erfahrungen inspirireren und unterstützen."
                  />
                  {!isDesktopView && <StickyComponent href="/terminbuchung" text="Jetzt beraten lassen." />}
                  <PodcastsStartPage />
                  <NewsletterPopUp isPodcastPage />
                  {' '}

                </>
                )}
            />

            {/*  END OF STATIC PAGES THAT CAN BE DELETED WHEN THEIR CONTENT IS IN WEBNIY */}
            <Route
              path="/termin"
              element={(
                <>
                  <SEO />
                  <Page />
                </>
            )}
            />
            <Route
              path="*"
              element={(
                <>
                  <SEO />
                  <Page />
                </>
            )}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Locator />
          <FooterComponent />
        </div>
      </Router>
    </Suspense>

  );
}

export default App;
