import React from 'react';
import { Grid } from '@material-ui/core';
import { footerProps, footerProps2 } from './mock';
import {
  Arrow,
  BlueText,
  DesktopView,
  FooterContainer,
  LinkItem,
  MobileView,
  StyledButton,
  StyledLink,
  TitleContainer,
} from './style';
import useWindowWidthCheck from '../../hooks/useWindowWidthCheck';
import { ctaAndButtonTracking } from '../../utils/adobeAnalytics/dataLayerContent';
import { adobeAnalyticsCtaAndButtonTracking } from '../../utils/adobeAnalytics/trackingFunctions';

interface LinkComponentProps {
  link: {
    title: string
    url?: string;
  };
  aaTrackingInfos: () => void;
}

function handleCookiebanner() {
  // @ts-ignore
  if (!UC_UI) return;
  // @ts-ignore
  UC_UI.showSecondLayer();
}

function LinkComponent({
  link,
  aaTrackingInfos,
}: LinkComponentProps) {
  return (
    <LinkItem container direction="row" justifyContent="flex-start" key={link.title}>
      <Arrow />
      {link.url
        ? (
          <StyledLink title={link.title} href={link.url} onClick={aaTrackingInfos}>
            {link.title}
          </StyledLink>
        )
        : (
          <StyledButton
            title={link.title}
            onClick={() => {
              handleCookiebanner();
              aaTrackingInfos();
            }}
            type="button"
          >
            {link.title}
          </StyledButton>
        )}
    </LinkItem>
  );
}

function FooterComponent() {
  const mobileMaxDisplaySize = 959;
  const isDesktopView = useWindowWidthCheck(mobileMaxDisplaySize);
  return (
    <FooterContainer container>
      {isDesktopView ? (
        <DesktopView container>
          <Grid container item xs={12}>
            <TitleContainer container item xs={6} alignItems="center">
              Mein
              <BlueText>
                Lehramt&nbsp;
              </BlueText>
              - eine Initiative der Versicherungskammer Bayern
            </TitleContainer>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              item
              xs={6}
            >
              <Grid item xs={6}>
                {footerProps.map((link) => {
                  if (link.title === 'Privatsphäre-Einstellungen') {
                    return (
                      <LinkComponent
                        link={link}
                        key={link.title}
                        aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                          text: link.title,
                        }))}
                      />
                    );
                  }
                  return (
                    <LinkComponent
                      link={link}
                      key={link.title}
                      aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                        text: link.title,
                        refLink: link.url,
                      }))}
                    />
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                {footerProps2.map((link) => {
                  if (link.title === 'Privatsphäre-Einstellungen') {
                    return (
                      <LinkComponent
                        link={link}
                        key={link.title}
                        aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                          text: link.title,
                        }))}
                      />
                    );
                  }
                  return (
                    <LinkComponent
                      link={link}
                      key={link.title}
                      aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                        text: link.title,
                        refLink: link.url,
                      }))}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DesktopView>
      ) : (
        <MobileView container direction="column">
          <Grid item xs={12}>
            <TitleContainer container>
              Mein
              <BlueText>
                Lehramt&nbsp;
              </BlueText>
              - eine Initiative der Versicherungskammer Bayern
            </TitleContainer>
            <Grid item xs={12}>
              {footerProps.concat(footerProps2)
                .map((link) => {
                  if (link.title === 'Privatsphäre-Einstellungen') {
                    return (
                      <LinkComponent
                        link={link}
                        key={link.title}
                        aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                          text: link.title,
                        }))}
                      />
                    );
                  }
                  return (
                    <LinkComponent
                      link={link}
                      key={link.title}
                      aaTrackingInfos={() => adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
                        text: link.title,
                        refLink: link.url,
                      }))}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </MobileView>
      )}
    </FooterContainer>
  );
}

export default FooterComponent;
