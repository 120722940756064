import React, {
  lazy, useEffect, useLayoutEffect, useState,
} from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { GET_PAGE } from '../api/query';
import { pageVar } from '../api/queryTemplate/Page';

import SkeletonLoader from '../../components/skeletonContentLoader';
import News from '../components/news';
import StickyComponent from '../../components/stickyComponent';
import useWindowWidthCheck from '../../hooks/useWindowWidthCheck';
import dataLayerHandler from '../../utils/adobeAnalytics/trackingFunctions';
import { adobeDynamicInitPageTracking } from '../../utils/adobeAnalytics/dataLayerContent';

const SliderAndText = lazy(() => import('../components/sliderAndText'));
const QuotesComponent = lazy(() => import('../components/quotes'));
const StartArticle = lazy(() => import('../components/startArticle'));
const ArticleParagraph = lazy(() => import('../components/articleParagraph'));
const IconImageBar = lazy(() => import('../components/iconImageBar'));
const NotFoundPage = lazy(() => import('../../pages/fehlerseite'));
const SocialSharingComponent = lazy(() => import('../components/socialSharing'));
const IconLinkBar = lazy(() => import('../components/iconLinkBar'));
const EyeCatcher = lazy(() => import('../../components/eyeCatcher'));
const NewsletterPopUp = lazy(() => import('../../components/newsletterPopup'));
const MapComponent = lazy(() => import('../components/map'));
const FAQ = lazy(() => import('../components/faq'));
const SuperArticleParagraph = lazy(() => import('../components/superArticleParagraph'));
const InfoBox = lazy(() => import('../components/infoBox'));
const InfoBoxWithButton = lazy(() => import('../components/infoBoxWithButton'));

function Page(): JSX.Element {
  const { pathname, hash } = useLocation();
  const [showInfo, setShowInfo] = useState((localStorage.getItem('showEyecatcher') === '1' && localStorage.getItem('showEyecatcherScroll') === '1'));
  const ankerId = hash.split('#')[1];
  const isDesktopView = useWindowWidthCheck(768);

  useEffect(() => {
    if (!localStorage.getItem('showEyecatcher')) {
      localStorage.setItem('showEyecatcher', '0');
    }
    dataLayerHandler(adobeDynamicInitPageTracking({
      pageName: pathname,
      pageParams: hash,
    }));
  }, []);

  useLayoutEffect(() => {
    if (document.getElementById('SEO_TEST')) {
      // @ts-ignore
      window.prerenderReady = true;
    }
  });

  const { error } = useQuery(GET_PAGE, { variables: { pageSlug: pathname }, errorPolicy: 'all' });
  const { isLoading: isLoadingSinglePage, data: singlePageData } = useReactiveVar(pageVar);
  useLayoutEffect(() => {
    if (isLoadingSinglePage) return;
    const ankerIdExists = document.getElementById(ankerId);
    if (ankerIdExists) {
      setTimeout(() => ankerIdExists.scrollIntoView(), 400);
    }
  }, [isLoadingSinglePage, ankerId]);

  if (isLoadingSinglePage) return (<SkeletonLoader />);
  if (singlePageData.length === 0) return (<NotFoundPage />);
  if (singlePageData.length > 1) {
    return (
      <div>
        <p>
          Unter dem Pageslug
          {singlePageData[0].pageSlug}
          {' '}
          existieren
          zwei Eintraege. Bitte pruefen.
        </p>
        {singlePageData.map((page, index) => (
          <p key={page.name}>
            Name des Contentmodell
            {' '}
            {index + 1}
            :
            {' '}
            {page.name}
          </p>
        ))}
      </div>
    );
  }

  let pageComponents: JSX.Element[] = [];
  singlePageData.forEach((page) => {
    pageComponents = (page.pageContent.map((component) => {
      if (component?.__typename === 'News') { return <News key={`News${component.name}`} data={component} buttonIdentifier={component.name} />; }
      if (component?.__typename === 'Quote') { return <QuotesComponent key={`Quote${component.name}`} data={component} />; }
      if (component?.__typename === 'SliderAndText') { return <SliderAndText key={`SliderAndText${component.name}`} data={component} />; }
      if (component?.__typename === 'StartArticle') { return <StartArticle key={`StartArticle${component.name}`} data={component} />; }
      if (component?.__typename === 'ArticleParagraph') { return <ArticleParagraph key={`ArticleParagraph${component.name}`} data={component} ankerId={ankerId} buttonIdentifier={component.name} />; }
      if (component?.__typename === 'SuperArticleParagraph') { return <SuperArticleParagraph key={`SuperArticleParagraph${component.name}`} data={component} ankerId={ankerId || ''} buttonIdentifier={component.name} />; }
      if (component?.__typename === 'Faq') { return <FAQ key={`Faq${component.name}`} data={component} />; }
      if (component?.__typename === 'IconImageBar') { return <IconImageBar key={`IconImageBar${component.name}`} data={component} />; }
      if (component?.__typename === 'SocialShare') { return <SocialSharingComponent key={`SocialShare${component.name}`} data={component} />; }
      if (component?.__typename === 'IconLinkBar') { return <IconLinkBar key={`IconLinkBar${component.name}`} data={component} />; }
      if (component?.__typename === 'MapContent') { return <MapComponent key={`MapContent${component.name}`} data={component} />; }
      if (component?.__typename === 'PlaceholderContentmodel1') { return <InfoBox key={`InfoBox${component.name}`} data={component} />; }
      if (component?.__typename === 'PlaceholderContentmodel2') { return <InfoBoxWithButton key={`InfoBoxWithButton${component.name}`} data={component} buttonIdentifier={component.name} />; }
      return (
        <>
          {error?.graphQLErrors.map(({ message }, i) => (
            console.error(message, i)
          ))}
          <p>Tut uns Leid, beim Laden des Elements ist ein Fehler aufgetreten!</p>
        </>
      );
    }));
    if (!['/termin', '/terminbuchung'].includes(page.pageSlug) && !isDesktopView) {
      pageComponents.push(<StickyComponent href="/terminbuchung" key={`StickyComponent${page.name}`} text="Jetzt beraten lassen." />);
    }
    if ('showDisruptor' in page && page.showDisruptor) { pageComponents.push(<StickyComponent href="/terminbuchung" key={`StickyComponent${page.name}`} text="Jetzt beraten lassen." />); }
    if (page.showEyeCatcher) { pageComponents.push(<EyeCatcher showInfo={showInfo} setShowInfo={setShowInfo} key={`EyeCatcher${page.name}`} />); }
    if (page.showNewsletterPopUp) { pageComponents.push(<NewsletterPopUp key={`Newsletter${page.name}`} />); }

    return (pageComponents);
  });

  if (pageComponents.length === 0) return <div> Dieses Contentmodell ist leer, bitte pruefen ob ein doppeltes besteht</div>;
  pageComponents.push(<span style={{ display: 'none' }} id="SEO_TEST" key="SEO_TEST" />);
  return (
    <>
      {pageComponents.map((component) => component)}
    </>
  );
}

export default Page;
