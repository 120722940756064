import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BlueButton, DownloadButton, Link, NewsButton,
} from './style';
import { handleAPICallAndRedirect } from './utils';
import { adobeAnalyticsCtaAndButtonTracking } from '../../utils/adobeAnalytics/trackingFunctions';
import { ctaAndButtonTracking } from '../../utils/adobeAnalytics/dataLayerContent';

interface ButtonProps {
  text: string;
  refLink?: string;
  buttonType?: 'submit' | 'reset' | 'button';
  needsValidation?: boolean;
  isDownload?: boolean;
  isNewTab?: boolean;
  isNews?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  fileName?: string;
  onClickIncrementCounter?: any;
  noneArrow?: boolean,
  buttonOnHoverColor?: string
  buttonIdentifier?: string;
}

function ButtonComponent({
  noneArrow,
  text,
  refLink,
  isNewTab = false,
  buttonType,
  fontColor,
  backgroundColor,
  fileName,
  needsValidation,
  isDownload,
  isNews,
  onClickIncrementCounter,
  buttonOnHoverColor,
  buttonIdentifier,
}: ButtonProps) {
  const {
    pathname,
  } = window.location;
  const handleButtonTracking = async (data: string | undefined, isNewTab: boolean) => {
    try {
      await handleAPICallAndRedirect({
        href: data || '/', isDownload: true, pathname, buttonIdentifier, isNewTab,
      });
      adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
        isDownload, needsValidation, refLink, text,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  if (needsValidation) {
    return (
      <Link>
        <BlueButton
          type={buttonType}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        >
          {text || 'Placeholder'}
          {noneArrow
            ?? <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />}
        </BlueButton>
      </Link>
    );
  }
  if (isDownload) {
    return (
      <Link
        download
        target={isNewTab ? '_blank' : ''}
        rel="noopener noreferrer"
        onClick={async () => handleButtonTracking(fileName, isNewTab)}
      >
        <DownloadButton
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          type="button"
          hoverColor={buttonOnHoverColor}
        >
          {text || 'Placeholder'}
          <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />
        </DownloadButton>
      </Link>
    );
  }
  if (isNews) {
    return (
      <Link
        onClick={() => handleButtonTracking(`${refLink}` || '/', isNewTab)}
        target={isNewTab ? '_blank' : ''}
        href={refLink}
      >
        <NewsButton
          backgroundcolor={backgroundColor}
          fontcolor={fontColor}
          hovercolor={buttonOnHoverColor}
          type="button"
          onClick={onClickIncrementCounter}
        >
          {text || 'Placeholder'}
          <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />
        </NewsButton>
      </Link>
    );
  }

  return (
    <Link
      onClick={async () => handleButtonTracking(`${refLink}` || '/', isNewTab)}
      target={isNewTab ? '_blank' : ''}
      rel="noreferrer"
    >
      <BlueButton
        type={buttonType}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        hovercolor={buttonOnHoverColor}
      >
        {text || 'Placeholder'}
        {noneArrow
          ?? <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />}
      </BlueButton>
    </Link>
  );
}

export default ButtonComponent;
