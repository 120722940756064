import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ArticleLinkHref, LinkContainer } from '../style';
import { theme } from '../../../../theme';
import { adobeAnalyticsCtaAndButtonTracking } from '../../../../utils/adobeAnalytics/trackingFunctions';
import { ctaAndButtonTracking } from '../../../../utils/adobeAnalytics/dataLayerContent';

interface ArticleProps {
  data: {
    pageSlug?: string,
    name: string
    depthOfElement?: number
  };
}

function MenuItemLink({
  data,
}: ArticleProps) {
  const {
    name,
    pageSlug,
    depthOfElement,
  } = data;

  const handleAdobeAnalytics = ({ linkName, linkUrl, navDepth }:{
    linkName: string,
    linkUrl?: string, navDepth?: number
  }) => {
    adobeAnalyticsCtaAndButtonTracking(ctaAndButtonTracking({
      text: linkName,
      refLink: linkUrl ?? '',
      ...(depthOfElement !== undefined && { depthOfElement: navDepth }),
    }));
  };

  return (
    <ArticleLinkHref
      key={name || ''}
      href={pageSlug || ''}
      onClick={() => handleAdobeAnalytics({
        linkName: name || '',
        linkUrl: pageSlug,
        navDepth: depthOfElement,
      })}
    >
      <LinkContainer>
        <ArrowForwardIcon
          fontSize="small"
          htmlColor={theme.color.blau1}
          className="arrow_item"
          aria-hidden="true"
          data-nosnippet
        />
      </LinkContainer>
      {name || ''}
    </ArticleLinkHref>
  );
}

export default MenuItemLink;
