import {
  AdobeAnalyticsCtaAndButtonTrackingProps,
  AdobeDynamicInitPageTrackingProps, AdobeTrackingButtonProps, ContactFormError,
  DataLayerPushProps, DatepickerBookDateError,
  FORMULAR_EVENTS,
  FORMULAR_STEP, FORMULAR_TYPE,
  FormularTrackingProps,
} from './types';
import { IErrorValue } from '../../pages/AdessoKlickstrecke/interfaces/formValue';

export const appointmentPicker: DataLayerPushProps = {
  pageName: '/terminbuchung',
  pageParams: '',
};

export const ahrefsSiteVerification: DataLayerPushProps = {
  pageName: '/ahrefs-site-verification_ba8bca77d195e5518efb3e5b71f9a6ea1524fc34c036487c2d5663795f389abd',
  pageParams: '',
};

export const marketeer: DataLayerPushProps = {
  pageName: '/marketeer',
  pageParams: '',
};

export const adessoKlickstreckeInit: DataLayerPushProps = {
  pageName: '/check',
  pageParams: '',
};

export const adessoKlickstreckeSuccess: DataLayerPushProps = {
  pageName: '/check/success',
  pageParams: '',
};

export const adessoKlickstreckeIntersectionContent: FormularTrackingProps = {
  formularName: 'check',
  formularStep: FORMULAR_STEP.CALL,
  formularType: '',
  event: FORMULAR_EVENTS.STEP,
};

export const gewinnspiel: DataLayerPushProps = {
  pageName: '/gewinnspiel',
  pageParams: '',
};

export const adessoKlickstreckeLastPageFormTrackingContent: FormularTrackingProps = {
  formularName: 'check',
  formularStep: 'lastPageForm',
  formularType: '',
  event: FORMULAR_EVENTS.STEP,
};

export const adessoKlickstreckeSubmitContent: FormularTrackingProps = {
  formularName: 'check',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const adessoKlickstreckeErrorContent = (errors: IErrorValue): FormularTrackingProps => ({
  formularName: 'check',
  formularStep: 'EWE',
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: Object.keys(errors)
    .reduce((acc, key) => {
      acc[key] = errors[key as keyof IErrorValue] || '';
      return acc;
    }, {} as { [key: string]: string }),
  event: FORMULAR_EVENTS.ERROR,
});

export const adessoKlickstreckeFormSteps = (step: string, formType: string, stepIndex: number): FormularTrackingProps => ({
  formularName: 'check',
  formularStep: step,
  formularType: formType,
  formularStepIndex: stepIndex,
  event: FORMULAR_EVENTS.STEP,
});

export const datepickerBookDateErrorContent = (errors: DatepickerBookDateError): FormularTrackingProps => ({
  formularName: 'terminbuchung',
  formularStep: 'termin buchen',
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: errors,
  event: FORMULAR_EVENTS.ERROR,
});

export const datepickerBookDateSubmitContent: FormularTrackingProps = {
  formularName: 'terminbuchung',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const datepickerFormObserverContent: FormularTrackingProps = {
  formularName: 'terminbuchung',
  formularStep: FORMULAR_STEP.CALL,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const datepickerDesiredDateContent: FormularTrackingProps = {
  formularName: 'terminbuchung',
  formularStep: 'wunschtermin gewaehlt',
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const datepickerFormErrorContent = (errors: DatepickerBookDateError): FormularTrackingProps => ({
  formularName: 'terminbuchung',
  formularStep: 'felder ausfuellen',
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: errors,
  event: FORMULAR_EVENTS.ERROR,
});

export const gewinnspielFormSubmitContent: FormularTrackingProps = {
  formularName: 'Gewinnspiel',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.COMPETITION,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const gewinnspielTeilnahmeContent: DataLayerPushProps = {
  pageName: '/teilnahme',
  pageParams: '',
};

export const contactPageContent: DataLayerPushProps = {
  pageName: '/kontakt',
  pageParams: '',
};

export const contactFormSubmitContent: FormularTrackingProps = {
  formularName: 'kontaktformular',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const contactFormIntersectionContent: FormularTrackingProps = {
  formularName: 'kontaktformular',
  formularStep: FORMULAR_STEP.CALL,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const contactSubmitFormErrorContent = (errors: ContactFormError): FormularTrackingProps => ({
  formularName: 'kontaktformular',
  formularStep: 'formular submit fehler',
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: errors,
  event: FORMULAR_EVENTS.ERROR,
});

export const financesAndSalaryPaper: DataLayerPushProps = {
  pageName: '/finanzen/gehaltszettel',
  pageParams: '',
};

export const expiredNewsletter: DataLayerPushProps = {
  pageName: '/newsletter/expired',
  pageParams: '',
};

export const newsletter: DataLayerPushProps = {
  pageName: '/newsletter',
  pageParams: '',
};

export const newsletterTrackingContent = ({ name, error }: {name: string, error: string}): FormularTrackingProps => ({
  formularName: 'newsletter anmeldung',
  formularStep: `${name} angeben`,
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: error ? { [name]: error } : {},
  event: error ? FORMULAR_EVENTS.ERROR : FORMULAR_EVENTS.STEP,
});

export const newsletterSubmitContent: FormularTrackingProps = {
  formularName: 'newsletter anmeldung',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const newsletterIntersectionContent: FormularTrackingProps = {
  formularName: 'newsletter anmeldung',
  formularStep: FORMULAR_STEP.CALL,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const newsletterPrivacyPolicyStatusContent = (eweCheckboxStatus: boolean): FormularTrackingProps => {
  const privacyPolicyStatus = eweCheckboxStatus ? 'akzeptiert' : 'nicht akzeptiert';
  return {
    formularName: 'newsletter anmeldung',
    formularStep: `datenschutz ${privacyPolicyStatus}`,
    formularType: FORMULAR_TYPE.CONTACT,
    event: FORMULAR_EVENTS.STEP,
  };
};

export const submitNewsletterWithoutPrivacyPolicyStatusContent = (errors: { [key: string]: string }): FormularTrackingProps => ({
  formularName: 'newsletter anmeldung',
  formularStep: 'datenschutz nicht akzeptiert',
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: errors,
  event: FORMULAR_EVENTS.ERROR,
});

export const podcastPageContent: DataLayerPushProps = {
  pageName: '/podcasts',
  pageParams: '',
};

export const podcastFormIntersectionContent: FormularTrackingProps = {
  formularName: 'podcast freischalten',
  formularStep: FORMULAR_STEP.CALL,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const podcastFullLengthSetMailContent: FormularTrackingProps = {
  formularName: 'podcast freischalten',
  formularStep: 'email angeben',
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.STEP,
};

export const podcastFullLengthSubmit: FormularTrackingProps = {
  formularName: 'podcast freischalten',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  event: FORMULAR_EVENTS.SUBMIT,
};

export const podcastFullLengthSubmitError = (errors: { [key: string]: string }): FormularTrackingProps => ({
  formularName: 'podcast freischalten',
  formularStep: FORMULAR_STEP.CONVERSION,
  formularType: FORMULAR_TYPE.CONTACT,
  formularErrors: errors,
  event: FORMULAR_EVENTS.ERROR,
});

export const ctaAndButtonTracking = ({
  needsValidation,
  isDownload,
  isSocialMediaButton,
  text,
  refLink,
  depthOfElement,
}: AdobeTrackingButtonProps): AdobeAnalyticsCtaAndButtonTrackingProps => {
  const trackingEvent = needsValidation
    ? 'cta_clicked'
    : isDownload
      ? 'download_clicked'
      : isSocialMediaButton
        ? 'social_signal_clicked'
        : 'cta_clicked';

  return {
    trackingEvent,
    pageSlug: window.location.pathname || '/',
    linkText: text,
    linkUrl: refLink || '/',
    linkDomain: window.location.host,
    linkCssClasses: 'button',
    ...(depthOfElement !== undefined ? { depthOfElement } : {}),
    event: 'tmsLinkClick',
  };
};

export const adobeDynamicInitPageTracking = ({
  pageName, pageParams,
}: AdobeDynamicInitPageTrackingProps): DataLayerPushProps => ({
  pageName,
  pageParams,
});
